const tools = [
    { name: "Generador de códigos QR", component: "QRGenerator" },
    { name: "Recordatorios por whatsapp y email tareas", component: null },
    { name: "Recordatorios por WhatsApp y correo electrónico para clientes: pronto pago", component: null },
    { name: "Chatbot parrillas redes", component: null },
    { name: "Chatbot pauta", component: null },
    { name: "Chatbot blogs", component: null },
    { name: "Cotización Automatica", component: null },
    { name: "Transcribir videos  - audios ( ingles, español)", component: null }
];

export default tools;
